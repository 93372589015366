.outlined-title {
  position: relative;
  margin-bottom: 20px;
  z-index: 1;
}

h1.outlined-title {
  font-size: 2.7rem;
  line-height: 3rem;
}

h2.outlined-title {
  font-size: 2.3rem;
  line-height: 2.7rem;
}

.outlined-title-shadow {
  color: transparent;
  -webkit-text-stroke: 2.5px var(--clr-primary);
  position: absolute;
  z-index: -1;
  left: 50%;
  right: 50%;
  font-size: calc(initial - 3%);
  transform: translateX(-50%);
  width: 100%;
  /* top: 30%; */
  font-size: 98%;
}

@media (min-width: 68rem) {
  h1.outlined-title {
    font-size: 7rem;
    line-height: 8.5rem;
  }

  h2.outlined-title {
    font-size: 6rem;
    line-height: 7rem;
  }

  .outlined-title-shadow {
    -webkit-text-stroke: 5px var(--clr-primary);
  }
}
