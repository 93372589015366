.page-video-wrapper {
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  visibility: hidden;
  transform: translateX(-100%);
  display: flex;
  width: 100%;
  z-index: 10001;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--clr-neutral-dark);
  opacity: 0;
  transition: all 100ms linear;
}
.page-video-wrapper.active {
  visibility: visible;
  transform: translateX(0);
  opacity: 1;
  transition: all 300ms linear;
}
.page-video-wrapper video {
  max-height: 100%;
}
.page-video-content {
  position: relative;
}

.close-icon {
  cursor: pointer;
  position: fixed;
  z-index: 1002;
  top: 20px;
  right: 30px;
}
.close-icon img {
  background-color: rgb(0, 0, 0, 0.2);
  padding: 20px;
  width: 80px;
}
