.sales-card {
  min-height: 370px;
  max-width: 222px;
  border: 2px solid;
  border-image-slice: 1;
  display: flex;
  align-items: center;
}

.accent-card {
  border-image-source: linear-gradient(
    180deg,
    #7f51bf 0%,
    rgba(127, 81, 191, 0.1) 100%
  );

  padding: 20px 10px;
}

.accent-card p {
  /* font-size: 14px; */
  font-weight: 500;
  /* line-height: 17px; */
}

.primary-card {
  border-width: 1.77px;
  border-image-source: linear-gradient(
    180deg,
    #fbd938 0%,
    rgba(251, 217, 56, 0.1) 100%
  );
  padding: 20px 10px;

  background-repeat: no-repeat;
  background-position: 90% 10%;
}

.first {
  background-repeat: no-repeat;
  background-position: 90% 10%;
}

.last {
  background-repeat: no-repeat;
  background-position: 90% 10%;
}

/* .primary-card p {
  font-weight: 400;
  line-height: 17px;
} */

@media (min-width: 68rem) {
  .sales-card {
    height: 424px;
    padding: 20px 10px;
    max-width: unset;
  }

  /* .accent-card p {
    font-size: 1.25rem;
    line-height: 1.5rem;
  }

  .primary-card p {
    font-size: 1rem;
    line-height: 19px;
  } */

  .first {
    background-image: url("https://cdn.jsdelivr.net/gh/mrbeban/problem-solver/images/arrow.png");
  }
}
