/* INVESTITORS */

.investitor {
  background-color: hsl(275, 59%, 6%);
  position: relative;
  overflow: hidden;
}

.investitor section {
  /* padding-block: 5rem; */
  text-align: center;
}

.text-section {
  padding-block: 4rem;
}

.intro .shadow {
  left: 0.7rem;
  top: 0.8rem;
}

.intro {
  user-select: none;
  /* padding-block: 2rem 4rem; */
  position: relative;
  padding-top: 15vh;
  padding-bottom: 10vh;

  z-index: 0;
}

.intro__wrapper {
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
}

.intro__header {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
}

.intro__cards {
  display: flex;
  flex-direction: column;
  gap: 5rem;
  /* justify-content: center; */
  align-items: center;
  /* padding-inline: 10rem; */
}

.intro__card {
  border: 2px solid;
  border-image-source: linear-gradient(
    180deg,
    #fbd938 0%,
    rgba(251, 217, 56, 0.1) 100%
  );
  border-image-slice: 1;
  max-width: 300px;
  flex: 1;
}

.card__img {
  background-blend-mode: multiply;
  background-size: cover;
  background-position: center;
  min-height: 12.5rem;
}

/* .intro__card p {
  font-size: 1rem;
  font-weight: 500;
  line-height: 19px;
  color: var(--clr-neutral);
} */

.intro__body {
  padding-block: 2rem;
}

.card-1 .card__text {
  padding: 3.25rem 4rem;
  background-color: var(--clr-accent-dark);
}

.card-2 .card__text,
.card-3 .card__text {
  padding: 3.25rem 1.5rem 2.5rem;
  background-color: var(--clr-accent-dark);
}

.cta {
  text-align: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 30%;
  background-attachment: fixed;
}

.cta__body p {
  padding-block: 6rem;
  /* font-size: 1rem;
  line-height: 1.5rem; */
}

.logo-section {
  padding-block: 5.625rem;
  position: relative;
}

.logo-section__body {
  display: flex;
  flex-direction: column;

  gap: 8.4rem;
}

.logo-section__important {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4.125rem;
}

.problems {
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.5) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  padding-block: 4.6875rem;
}

.problems__header {
  text-align: left;
}

.problems__wrapper {
  display: flex;
  flex-direction: column;
  text-align: justify;
  gap: 2.5rem;
}

.problems__examples {
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 3.25rem;
}

.example {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 1.375rem;
  padding-inline: 3.5rem;
}

.split {
  display: flex;
  flex-direction: column;
  text-align: justify;
  gap: 2rem;
  align-items: center;
  /* grid-auto-flow: column; */
  /* padding-inline: 14.375rem; */
  /* grid-template-columns: 1fr 2fr; */
}

.why-us .shadow {
  left: 0.5rem;
  top: 1rem;
  -webkit-text-stroke-color: var(--clr-accent);
}

.why-us {
  padding-block: 2.5rem;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));
}

.why-us__wrapper {
  text-align: justify;
  display: flex;
  flex-direction: column;
  gap: 5vh;
  align-items: center;
}

.why-us__header {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3.875rem;
}

.why-us__body {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.why-us_questions {
  display: flex;
  flex-direction: column;
  gap: 3vh;
}
.question-wrapper {
  padding: 30px 20px;
  border: 2px solid;
  border-image-source: linear-gradient(
    180deg,
    #7f51bf 0%,
    rgb(127, 81, 191, 0.1) 100%
  );
  border-image-slice: 1;
  height: 220px;
  display: flex;
  gap: 10%;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  overflow: hidden;
}

.question-wrapper h3 {
  color: var(--clr-accent);
  text-align: center;
}
.question-wrapper p {
  color: var(--clr-accent);
  /* font-weight: bolder; */
  text-align: center;
}

.why-us__question {
  text-align: center;
  margin-bottom: 5vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.why-us__answer p {
  /* line-height: 1.3rem; */
  text-align: center;
  /* font-size: 1rem; */
}

.process .shadow {
  left: 0.5rem;
  top: 0.8rem;
}

.process {
  padding-block: 2.5rem 0;
  min-height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 7rem;
}

.process__wrapper {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 90vh;
  position: relative;
}

.process-shadow {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  transform: scale(1.03);
  height: auto;
  z-index: 1;
  opacity: 0.5;
}

.process__body {
  display: flex;
  flex-direction: column;
  gap: 3.75rem;
  align-items: center;
  /* padding-inline: 3.25rem; */
}

.process__circle {
  position: relative;
}

.process__circle img {
  min-width: 25rem;
  /* min-height: 22rem; */
  /* border-radius: 50%; */
  /* background-color: var(--clr-accent); */
}

@media (min-width: 68em) {
  .intro .shadow {
    left: 1.5rem;
    text-align: center;
    top: 2rem;
  }

  .intro__wrapper {
    gap: 6.5rem;
  }

  .intro__header {
    gap: 5rem;
  }

  .intro__cards {
    flex-direction: row;
    align-items: unset;
  }

  .card__img {
    min-height: 15.625rem;
  }

  .card-1 .card__text {
    padding: 3.5rem 4.5rem 8.6rem;
  }

  .card-2 .card__text {
    padding: 3.5rem 2.5rem 5rem;
  }

  .card-3 .card__text {
    padding: 3.5rem 2.5rem 7rem;
  }

  .intro__card {
    max-width: 380px;
  }

  /* .intro__card p {
    font-family: Inter;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: center;
  } */

  .intro__body {
    padding: 4rem 7rem 4.6rem;
  }

  /* .intro__body p {
    padding-inline: 7rem;
    font-size: 1.25rem;
    line-height: 1.5rem;
  } */

  .cta__body {
    padding-inline: 8rem;
  }

  /* .cta__body p {
    font-size: 1.5rem;
    line-height: 2.75rem;
  } */

  .logo-section {
    padding-block: 8.4375rem;
  }

  .logo-section__body {
    padding-inline: 7rem;
    gap: 10rem;
  }

  /* .logo-section__body p {
    font-size: 1.25rem;
    line-height: 1.5rem;
  } */

  .logo-section__important {
    gap: 9rem;
  }

  .problems {
    padding-block: 6.4375rem 10rem;
  }

  .problems__header {
    text-align: center;
  }

  .problems__wrapper {
    gap: 5rem;
    text-align: center;
  }

  /* .problems__wrapper p {
    font-size: 1.25rem;
    line-height: 1.5rem;
  } */

  .problems__examples {
    flex-direction: row;
    justify-content: center;
  }

  .example {
    gap: 2rem;
    padding-inline: 1rem;
    flex: 1;
  }

  .split {
    flex-direction: row;
    align-items: center;
    gap: 3rem;
  }

  .split > *:nth-child(1) {
    flex: 1 1 80%;
  }

  .split__body {
    flex: 1 1 100%;
    text-align: left;
  }

  .why-us .shadow {
    top: 2.5rem;
    left: 1.5rem;
  }

  .why-us {
    padding-block: 11rem;
  }

  /* .why-us__wrapper {
    gap:vh;
  } */

  .why-us__header {
    text-align: center;
  }

  .why-us__body {
    gap: 6.75rem;
    max-width: 1000px;
  }

  .why-us_questions {
    flex-direction: row;
    gap: 3%;
  }
  .why-us_questions .question-wrapper {
    flex: 1;
  }

  .process .shadow {
    top: 2.5rem;
    left: 1rem;
    font-size: 5.9375rem;
    line-height: 7.1875rem;
  }

  /* .process__body p {
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.5rem;
  } */

  .process__body span.primary {
    letter-spacing: 0.1em;
    font-weight: 700;
  }

  .process__body {
    gap: 7.25rem;
    align-items: flex-start;
    /* padding-left: 6rem; */
  }
}
