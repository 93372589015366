/* TRADITIONAL MARKETING */

.marketing {
  background-position: center center;
  background-blend-mode: multiply;
  background-color: rgba(16, 6, 23, 0.5);
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  z-index: 0;
  padding-top: 15vh;
  overflow: hidden;
}

.marketing section {
  padding-block: 1.5625rem;
}

.flex-split {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 2.6875rem;
}

.last-stop {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 3.125rem;
}

.info {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  width: 100%;
}

.info .shadow {
  left: 0.5rem;
  top: 0.7rem;
  -webkit-text-stroke-color: var(--clr-accent);
}

.marketing__finish {
  padding-block: 1.5625rem 10rem !important;
}

.column-reverse {
  flex-direction: column-reverse;
}

.carousel {
  width: 90vw;
}

.carousel button {
  background-color: transparent;
  padding: 0 !important;
}

@media (min-width: 68rem) {
  .marketing section {
    padding-block: 10rem;
  }

  .flex-split {
    flex-direction: row;
  }

  .flex-split > *:nth-child(1) {
    flex: 1 1 45%;
  }

  .flex-split > *:nth-child(2) {
    flex: 1 1 55%;
  }

  .last-stop {
    padding-inline: 5rem;
    /* gap: 5rem; */
  }
  .last-stop .marker {
    min-height: 330px;
  }

  .info {
    gap: 3.5rem;
    width: unset;
    /* margin: 30rem; */
  }

  .carousel {
    width: 800px;
  }

  .info .shadow {
    left: 1.25rem;
    top: 2.5rem;
    font-size: 5.9375rem;
    line-height: 7.1875rem;
  }

  .column-reverse {
    flex-direction: row;
  }

  .column-reverse > *:nth-child(1) {
    flex: 1 1 55%;
  }

  .column-reverse > *:nth-child(2) {
    flex: 1 1 45%;
  }
}
