:root {
  --clr-primary: #face2c;
  --clr-accent: rgb(127, 81, 191);
  --clr-accent-dark: rgb(16, 6, 23);
  --clr-neutral: #ffff;
  --clr-neutral-dark: #000000;

  --ff-primary: "Montserrat", "sans-serif";
  --transition-speed: 1s;
}

/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
h5,
p,
figure,
blockquote,
dl,
dd,
ul {
  margin: 0;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul,
ol {
  list-style: none;
  padding: 0;
}

/* Set core root defaults */
html:focus-within {
  scroll-behavior: smooth;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
  background-color: var(--clr-accent-dark);
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img,
picture {
  max-width: 100%;
  display: block;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }

  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

/*Typography*/

body {
  font-family: var(--ff-primary);
  color: var(--clr-neutral);
  overflow-x: hidden;
}

h1,
h2 {
  font-size: 3rem;
  line-height: 3.2rem;
  position: relative;
}

h3 {
  font-size: 1.5rem;
  line-height: 2rem;
}

h4 {
  font-size: 1.25rem;
  line-height: 1.5rem;
}

h5 {
  font-size: 1rem;
  line-height: 1.25rem;
}

a {
  font-size: 2.25rem;
  line-height: 2.75rem;
  text-decoration: none;
  font-weight: 900;
}

span:not([class]) {
  font-weight: 500;
  font-style: italic;
}

p {
  color: var(--clr-neutral);
  font-size: 1rem;
  line-height: 1.125rem;
}

p.intensified {
  font-size: 1.125rem;
  line-height: 1.425rem;
}

button {
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.shadow {
  color: transparent;
  position: absolute;
  -webkit-text-stroke: 1.3px var(--clr-primary);
  font-size: 35px;
  line-height: 42px;
}

.first .shadow {
  top: 0.7rem;
  left: 1rem;
}

.second .shadow {
  top: 0.7rem;
  left: 0.7rem;
}

.fourth .shadow {
  top: 0.7rem;
  left: 0.3rem;
  font-size: 2.8rem;
  line-height: 3.25rem;
}

.third p {
  font-weight: 900;
  font-size: 24px;
  line-height: 29px;
}

@media (min-width: 68rem) {
  a {
    font-size: 3rem;
  }
  p {
    font-size: 1.125rem;
    line-height: 1.375rem;
  }

  h1 {
    font-size: 7.5rem;
    line-height: 9.0625rem;
    /* -webkit-text-stroke: 4px solid var(--clr-neutral); */
  }

  h2 {
    font-size: 6.5625rem;
    line-height: 7.9375rem;
  }

  h3 {
    font-size: 2rem;
    line-height: 2.5rem;
  }

  h4 {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  h5 {
    font-size: 1.25rem;
    line-height: 1.875rem;
  }

  p.intensified {
    font-size: 1.375rem;
    line-height: 1.7rem;
  }

  .third p {
    font-size: 24px;
    line-height: 29.05px;
  }

  .text-wrapper {
    max-width: 1000px;
    margin: 0 auto;
  }
}

/* Layout */

/* Utility classes */

.primary {
  color: var(--clr-primary);
}

.accent {
  color: var(--clr-accent);
}

.fade-right {
  animation: fadeFromRight 0.5s ease-in;
}

.pulse {
  animation: pulse 1s linear infinite;
  transition: linear;
}

@keyframes pulse {
  0% {
    transform: scale(0.9);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.9);
  }
}

@media (min-width: 68rem) {
  .custom-section {
    padding-inline: 3rem;
  }
}

@keyframes fadeFromRight {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.loadingVideoWrapper {
  min-height: 100vh;
  position: fixed;
  display: none;
  width: 100%;
  z-index: 10001;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--clr-neutral-dark);
  transition: all 100ms linear;
  opacity: 0;
}
.loadingVideoWrapper.revert {
  transition: opacity 400ms linear;
  opacity: 0;
  display: flex;
}
.loadingVideoWrapper.show {
  transition: opacity 400ms linear;
  display: flex;
  opacity: 1;
}

.play-video-wrapper {
  position: fixed;
  opacity: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all linear 300ms;
}

.play-video-wrapper.active {
  opacity: 1;
  transition: all linear 300ms;
}

.container {
  --padding: 1.4rem;
  --max-width: 1500px;
  width: min(var(--max-width), 100% - (var(--padding) * 2));
  margin-inline: auto;
}

.primary-btn {
  color: var(--clr-neutral-dark);
  background-color: var(--clr-primary);
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  padding: 10px 3rem;
  border-radius: 3px;
}

.primary-btn:hover {
  box-shadow: 0 5px 15px var(--clr-primary);
}

.accent-btn {
  color: var(--clr-neutral);
  background-color: var(--clr-accent);
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  padding: 10px 3rem;
  border-radius: 3px;
}

.accent-btn:hover {
  box-shadow: 0 5px 15px var(--clr-accent);
}

@media (min-width: 68em) {
  .primary-btn,
  .accent-btn {
    font-size: 24px;
    line-height: 29px;
    padding: 14px 4rem;
    border-radius: 5px;
  }
}
