/* NAVMENU */

.navmenu {
  position: fixed;
  inset: 0;
  background-color: transparent;
  z-index: 2;
  padding: 3vh 1rem 0 10%;
  transform: translateX(-100%);
  transition: transform 0.5s ease-out;
  overflow: hidden;
  z-index: 999;
}

.primary-navigation {
  display: grid;
  grid-template-areas:
    ". logo"
    "nav-list nav-list "
    "info info "
    "social-links social-links ";
  /* min-height: 100%; */
}

.primary-navigation .social-links {
  margin-top: 2rem;
}

.navmenu.visible {
  transform: translateX(0%);
  background-color: var(--clr-accent-dark);
  transition: background-color 0.7s, transform 0.5s opacity 0.7s;
}

.nav__list {
  grid-area: nav-list;
  display: flex;
  flex-direction: column;
  margin-block: min(1.875rem, 2vh);
  /* gap: 1.625rem; */
  gap: 2vh;
  /* margin-top: 3rem; */
  overflow: hidden;
}
.nav__list {
  overflow: hidden;
}

.nav__list a:hover {
  transform: translate(1.75rem, 0);
  -webkit-text-fill-color: var(--clr-neutral);
}

.nav__list a {
  transition: var(--transition-speed);
  display: inline-block;
  background: linear-gradient(
    90deg,
    #ffffff 0%,
    rgba(255, 255, 255, 0) 111.32%
  );
  background-clip: text;
  -webkit-text-fill-color: transparent;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.navmenu.visible .nav__logo {
  opacity: 1;
  transition: 1s;
}

.navmenu .social-links {
  grid-area: social-links;
  display: flex;
  justify-content: flex-start;
}
.navmenu .nav__info {
  align-items: flex-start;
  margin-top: 2rem;
}

/* .navmenu p {
  font-size: 1.25rem;
  line-height: 1.5rem;
} */

@media (min-width: 93.75em) {
  .navmenu {
    /* padding: 3.5rem 1.875rem 0 3.9375rem; */
    padding: 0;
  }
  .navmenu p {
    font-weight: 300;
  }
  .primary-navigation {
    /* padding: 2rem; */
    /* grid-template-columns: 1fr minmax(min-content, max-content) 1fr 1fr; */
    /* grid-template-rows: 1fr 50% 1fr; */
    /* margin: 2.5rem 3rem; */
    grid-template-areas:
      "close-icon . social-links social-links"
      ". nav-list logo ."
      ". nav-list info .";

    row-gap: 5vh;
  }
  .primary-navigation .social-links {
    visibility: hidden;
    margin-top: 0;
  }
  .nav__list {
    grid-area: nav-list;
    display: flex;
    flex-direction: column;
    gap: 0;
    margin: 0;
  }

  .nav__list a {
    font-size: 5rem;
    line-height: 6rem;
  }

  .nav__list a:hover {
    transform: translate(3.75rem, 0);
    transition: 0.5s;
  }

  .navmenu .nav__info {
    align-items: flex-end;
    margin-top: 0;
  }
}
