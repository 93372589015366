.to-top-btn {
  width: auto;
  height: auto;
  opacity: 0;
  top: 50%;
  bottom: 50%;
  transform: translateY(-50%) translateX(-100%) rotate(-90deg);
  left: 0.5rem;
  position: fixed;
  z-index: 998;
  color: var(--clr-neutral-white);
  padding: 30px;
  transition: all 0.2s ease-in;
}
.to-top-btn.show {
  transform: translateY(-50%) translateX(-45%) rotate(-90deg);
  opacity: 1;
  transition: all 0.5s ease-in;
}

.to-top-btn-wrapper {
  /* transform: rotate(-90deg); */
  gap: 10px;
  width: auto;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center;
}

.to-top-btn-wrapper p {
  text-transform: uppercase;
  font-size: 0.8rem;
}
.to-top-btn-wrapper i {
  font-size: 0.8rem;
}

@media (min-width: 93.75em) {
  /* .to-top-btn {
    left: 4rem;
  } */
  .to-top-btn-wrapper p {
    font-size: 1rem;
  }
  .to-top-btn-wrapper i {
    font-size: 1rem;
  }
}
