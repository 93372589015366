.marker {
  position: relative;
  display: flex;
  justify-content: center;
  padding-inline: 4rem;
  z-index: 2;
}

.marker-wrapper {
  position: relative;
}

.marker__inner {
  position: absolute;
  width: 90%;
  top: 3%;
  left: 50%;
  right: 50%;
  transform: translateX(-50%);
  /* left: 0.7rem;
  right: 0.7rem; */
}

.marker__gif {
  /* width: 200%; */
  transform: translateX(-50%) translateY(40%) scale(180%);
  clip-path: circle();
}

@media (min-width: 68rem) {
  .marker__inner {
    top: 1rem;
  }
  .marker-wrapper {
    min-height: 550px;
  }

  .marker__dot {
    position: absolute;
    bottom: 0;

    width: 22px;
    aspect-ratio: 1;
    border-radius: 50%;
    background: linear-gradient(0deg, #7f51bf, #7f51bf), #000000;
  }

  .sales-example-2 .marker__dot {
    right: 50px !important;
    top: 50%;
    visibility: hidden;
  }

  .sales-example-1 .marker__dot,
  .sales-example-3 .marker__dot {
    left: 50px;
    top: 50%;
    visibility: hidden;
  }
}
