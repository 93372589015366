/*  NAVIGATION  */

/*###########  TODO  ##########
Put higher value for z-index of header and use flex for everything else,
social-links and logo put in same box with one or both with display none

Put multiple media queries
#########################*/

header {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 3vh 5%;
  width: 100%;
  position: fixed;
  z-index: 1001;
  transition: all 0.5 linear;
}
header.revert .social-links {
  transform: scale(0.8);
  transition: all 0.2s linear;
}
header.hide .social-links {
  transform: scale(0.8) translateY(-250%);
  transition: all 0.2s linear;
}
header.active .social-links {
  transform: translateY(0) scale(1);
  transition: all 0.2s linear;
}
header.fixed .social-links {
  transform: translateY(0);
}

/* BURGER BUTTON ANIMATION */
.burger-icon {
  /* position: fixed;
  z-index: 10; */
  /* z-index: 100; */
  position: fixed;
  z-index: 1001;
  top: 3vh;
  left: 3%;
}
.hamburger-lines {
  display: block;
  height: 28px;
  width: 36px;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.hamburger-lines:hover .line {
  background-color: var(--clr-primary);
}

.hamburger-lines .line {
  display: block;
  height: 3px;
  width: 100%;
  background: white;
}
.hamburger-lines .line1 {
  transform: scaleX(120%);
  transform-origin: 0% 0%;
  transition: transform 0.4s ease-in-out;
}
.hamburger-lines .line2 {
  transition: transform 0.2s ease-in-out;
}
.hamburger-lines .line3 {
  transform: scaleX(140%);
  transform-origin: 0% 100%;
  transition: transform 0.4s ease-in-out;
}
.burger-icon.active .hamburger-lines .line1 {
  transform: rotate(45deg);
}
.burger-icon.active .hamburger-lines .line2 {
  transform: scaleY(0);
}
.burger-icon.active .hamburger-lines .line3 {
  transform: rotate(-45deg);
}

.nav__close {
  position: fixed;
  z-index: 10;
  grid-area: close-icon;
  max-width: 1.625rem;
  aspect-ratio: 1;
}

.nav__logo {
  display: none;
}

.nav__mobile-logo {
  grid-area: logo;
  display: flex;
  justify-content: flex-end;
}

.nav__info {
  grid-area: info;
}

a.active {
  -webkit-text-fill-color: #fbd938 !important;
}

/* .show {
  display: none;
} */

@media (min-width: 93.75em) {
  /* .header .social-links {
    position: fixed;
  } */

  .hamburger-lines {
    /* left: 20px; */
    height: 35px;
    width: 45px;
  }
  .hamburger-lines .line {
    height: 4px;
  }

  .burger-icon {
    max-width: 100%;
  }

  .nav__close {
    grid-area: close-icon;
    max-width: 4.9375rem;
    aspect-ratio: 1;
    /* visibility: hidden; */
  }

  .nav__logo {
    grid-area: logo;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    opacity: 0;
    transition: opacity 0.2s ease-in;
  }

  .nav__mobile-logo {
    display: none;
  }
}
