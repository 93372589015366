.app-wrapper {
  background-color: var(--clr-accent-dark);
  position: relative;
}
.scrollableContainer {
  overflow-y: scroll;
  height: 100vh;
  overflow-x: hidden;
  scroll-behavior: smooth;
}
.scrollableContainer::-webkit-scrollbar {
  width: 8px; /*width of the entire scrollbar*/
}

.scrollableContainer::-webkit-scrollbar-track {
  background: transparent; /* color of the tracking area */
}

.scrollableContainer::-webkit-scrollbar-thumb {
  background-color: var(--clr-accent); /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
  /* creates padding around scroll thumb */
  border: 3px solid transparent;
  padding: 10px 0;
}
