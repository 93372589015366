.progress-page {
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: 10000;
  display: none;
  justify-content: center;
  align-items: center;
  background-color: var(--clr-accent-dark);
}

.progress-page.revert {
  transition: opacity ease-out 0.5s;
  opacity: 0;
}
.progress-page.show {
  opacity: 1;
  transition: opacity ease-out 0.5s;
  display: flex;
}

.progress {
  background: rgba(255, 255, 255, 0.1);
  justify-content: flex-start;
  border-radius: 1px;
  align-items: center;
  position: relative;
  padding: 0 3px;
  display: flex;
  height: 11px;
  width: 90%;
}

.progress-value {
  animation: load 5s linear forwards;
  box-shadow: 0 3px 10px -2px #fff;
  border-radius: 1px;
  background: #fff;
  height: 5px;
  width: 0;
}

@keyframes load {
  0% {
    width: 0;
  }
  100% {
    width: 85%;
  }
}

@media (min-width: 68rem) {
  .progress {
    width: 450px;
  }
}
