/* HOME PAGE */

.home {
  min-height: 100vh;
  position: relative;
  z-index: 10;
  background-color: var(--clr-accent-dark);
  overflow: hidden;
}

.particles-here {
  position: absolute;
  inset: 0;
  z-index: -1;
}

.home__menu {
  padding: 20% 8vh 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  text-align: center;
  gap: 4vh;
  position: relative;
  padding-bottom: 10vh;
  z-index: -1;
  height: 100vh;
}

.home-menu-item {
  word-wrap: break-word;
  max-width: 200px;
  font-size: 18px;
}
.home-menu-item:last-child {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
}
#about-home-menu-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

#about-home-menu-item > *:nth-child(2) {
  width: 20%;
}

.home-menu-item h2 {
  font-size: 1.75rem;
  font-weight: 900;
  line-height: 2.3rem;
  text-decoration: none;
  /* display: inline-block; */
}

.home-menu-item {
  display: inline-block;
  background: linear-gradient(
    182.26deg,
    #ffffff 21.26%,
    rgba(255, 255, 255, 0.5) 98.11%
  );
  -webkit-text-fill-color: white;
  background-clip: text;
  /* text-shadow: 0px 4px 20px rgba(255, 255, 255, 0.5); */
}

.home-menu-item:hover,
.home-menu-item:active {
  background-color: var(--clr-accent);
  -webkit-text-fill-color: var(--clr-accent);
  color: white;
  background-clip: text;
  text-shadow: 0px 4px 20px rgba(255, 255, 255, 0.25);
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: -1;
  height: 100%;
  padding-top: 10%;
  /* min-width: 100vw; */
  inset: 0;
  /* min-height: 100vh; */
}

@media (min-width: 43.75rem) {
  .home__menu {
    position: relative;
    justify-content: center;
    width: 100%;
    /* padding: 0; */
    /* margin: 10rem auto 0; put 20 instead of 12 */
    height: 100vh;
    /* padding-bottom: 50vh; */
  }

  .home-menu-item {
    /* display: block; */
    max-width: 300px;
    position: absolute;
    /* top: 50%; */
    /* left: 50%; */
    /* width: 4em; */
    /* height: 4em; */
    /* margin: -2em; */
  }
  .home-menu-item h2 {
    font-size: 2rem;
    line-height: 2.7rem;
  }

  .center {
    min-width: 100vw;
    inset: unset;
    min-height: 100vh;
    padding-top: unset;
  }

  .deg10 {
    transform: rotate(10deg) translate(40vw) rotate(-10deg) translateX(-7vw)
      translateY(10vw);
  }
  .deg90 {
    transform: rotate(90deg) translate(30vh) rotate(-90deg);
  }
  .deg180 {
    transform: rotate(170deg) translate(40vw) rotate(-170deg) translateX(7vw)
      translateY(10vw);
  }
  .deg225 {
    transform: rotate(225deg) translate(30vw) rotate(-225deg) translateX(-10vw)
      translateY(3vw);
  }
  .deg315 {
    transform: rotate(315deg) translate(30vw) rotate(-315deg) translateX(10vw)
      translateY(3vw);
  }

  /* .background {
    width: 50%;
    height: 50%;
  } */
}

@media (min-width: 68rem) {
  .home__menu {
    padding: 0;
  }

  .home-menu-item {
    max-width: 400px;
  }

  .home-menu-item h2 {
    font-size: 3rem;
  }

  .deg10 {
    transform: rotate(10deg) translate(40vw) rotate(-10deg) translateX(-7vw)
      translateY(5vw);
  }
  .deg90 {
    transform: rotate(90deg) translate(35vh) rotate(-90deg);
  }
  .deg180 {
    transform: rotate(170deg) translate(40vw) rotate(-170deg) translateX(7vw)
      translateY(5vw);
  }
  .deg225 {
    transform: rotate(225deg) translate(30vw) rotate(-225deg) translateX(-10vw)
      translateY(7vw);
  }
  .deg315 {
    transform: rotate(315deg) translate(30vw) rotate(-315deg) translateX(10vw)
      translateY(7vw);
  }
}
