.nav__info {
  grid-area: info;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-direction: column;
}

.nav__info p {
  font-size: 0.875rem;
  line-height: normal;
}

@media (min-width: 68rem) {
  .nav__info p {
    /* font-size: 0.875rem; */
    font-size: 1.2rem;
  }
}
