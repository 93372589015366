/*SALES PAGE*/

.sales {
  background-color: var(--clr-accent-dark);
  position: relative;
  z-index: 0;
  overflow: hidden;
}

.sales section {
  text-align: center;
}

.sales-intro {
  padding-block: 5rem 4.5rem;
  padding-top: 15vh;
  position: relative;
}
.sales-intro__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3rem;
}

.sales-intro__header {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4rem;
}

.sales-intro__body {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 3.25rem;
  min-height: 600px;
}
/* .sales-intro__body div {
  flex: 1;
} */

.sales-intro__example {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 1.375rem;
  position: relative;
  z-index: 2;
}

.sales-cards {
  padding-block: 5rem;
  min-height: 100vh;
  display: flex;
  align-items: center;
}

.sales-cards__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 13px;
  /* padding-inline: 17%; */
}

.sales-cards__wrapper > * {
  flex: 1;
}

/* .sales-cards__wrapper .sale-card-wrapper {
  flex: 1;
  position: relative;
} */

.roadmap {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: relative;
}
.roadmap-shadow {
  position: absolute;
  background-color: #040007;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.roadmapPart--primary {
  /* padding-top: 6.5625rem; */
  padding-left: 3.75rem;
}

.sales-problems {
  padding: 5rem 1.4375rem;
  background: rgba(0, 0, 0, 0.5);
}

.sales-problems__wrapper {
  display: flex;
  flex-direction: column;
  text-align: left;
  align-items: center;
  gap: 3.125rem;
}

.sales-problems__header {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
}

.sales-problems-header__intro {
  display: flex;
  flex-direction: column;
  gap: 3.5625rem;
}

.sales-problems__logo {
  display: inline-block;
  width: 40%;
}

.sales-problems__examples {
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 2.125rem;
}

.sales-problems__example {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 1.375rem;
}

.sales-problems__conclusion {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3.25rem;
}

.sales .split {
  flex-direction: column-reverse;
  margin-bottom: 50px;
}
.sales .title {
  margin-bottom: 70px;
}
/* .sales .title h3 {
  font-size: 1.4rem;
} */

.sales-mini-cards-wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.sales-mini-card {
  padding: 40px 20px;
  border: 2px solid;
  border-image-source: linear-gradient(
    180deg,
    #7f51bf 0%,
    rgb(127, 81, 191, 0.1) 100%
  );
  border-image-slice: 1;
  background-color: #100617c7;
}
.sales-mini-card p {
  text-align: center;
}

.solution {
  padding: 4.5rem 2rem 2.625rem;
  background: rgba(0, 0, 0, 0.5);
}

.solution__wrapper {
  display: flex;
  flex-direction: column;
  gap: 3.75rem;
}

/* .solution__header h3 {
  font-size: 1.25rem;
  line-height: 1.5rem;
} */

.solution__examples {
  display: flex;
  flex-direction: column;
  gap: 3.3rem;
  align-items: center;
}

.solution__example {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
}

#sales-philosophy {
  padding: 10vh 10%;
  background-image: linear-gradient(rgba(34, 22, 43), rgba(0, 0, 0, 0.9));
}

.whats-next {
  padding-block: 4.375rem;
  background: #040007;
  min-height: 100vh;
}

.whats-next__wrapper {
  display: flex;
  flex-direction: column;
  gap: 3.3rem;
}

.whats-next__header {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.whats-next .shadow {
  left: 1rem;
  top: 0.7rem;
  -webkit-text-stroke-color: var(--clr-accent);
}

.sales-end {
  padding: 5rem 0.5rem 15rem;
  background: rgba(34, 22, 43, 0.9);
}

.sales-end__wrapper {
  display: flex;
  flex-direction: column;
  gap: 4.375rem;
}

.sales-end__questions {
  display: flex;
  flex-direction: column;
  gap: 4.25rem;
  align-items: center;
}

.sales-end__question {
  width: 350px;
  height: 294px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 2px solid;
  border-image-source: linear-gradient(
    180deg,
    #7035ab 0%,
    rgba(112, 53, 171, 0.25) 100%
  );
  border-image-slice: 1;
  filter: grayscale(100%);
  background-color: rgba(0, 0, 0, 0.5);
  background-blend-mode: multiply;
  padding-inline: 3.5rem;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  transition: all linear 200ms;
}

.sales-end__question:hover {
  filter: grayscale(40%);
  background-color: rgba(0, 0, 0, 0.3);
}

@media (min-width: 68rem) {
  .sales .container {
    --max-width: 1700px;
  }

  .sales-intro__wrapper {
    gap: 4.3rem;
  }

  /* .sales-intro h1 {
    line-height: 8.3125rem;
  } */

  .sales-intro .shadow {
    top: 2rem;
    left: 1.5rem;
  }

  .sales-intro__body {
    /* flex-direction: row; */
    gap: 12rem;
  }

  /* .sales-intro__body h3 {
    font-size: 24px;
  } */
  /* .sales-intro__body p {
    font-size: 20px;
    line-height: 24px;
  } */

  .sales-example__wrapper {
    display: flex;
    justify-content: flex-start;
    text-align: left;
  }

  .sales-example__wrapper:nth-child(2) {
    justify-content: flex-end;
    text-align: left;
    width: 100%;
  }

  .sales-intro__example {
    width: 70%;
    flex-direction: row-reverse;
    gap: 2rem;
    justify-content: center;
  }
  /* .sales-intro__example p {
    line-height: 1.3;
  } */

  .sales-problems__logo {
    width: 20%;
  }

  .sales-example-1 {
    text-align: right;
  }
  .sales-example-2 {
    flex-direction: row;
    text-align: left;
  }
  .sales-example-3 {
    text-align: right;
  }

  .sales-intro__example > * {
    flex: 1;
  }

  .sales-example__icon {
    display: flex;
    justify-content: flex-start;
  }
  .sales-example__icon img {
    min-width: 100px;
    min-height: 100px;
    border: none;
  }

  .sales-example-2 .sales-example__icon {
    justify-content: flex-end;
  }

  .sales-cards__wrapper {
    flex-direction: row;
    padding-inline: 2rem;
    gap: 23px;
  }

  .sales-problems {
    padding-block: 8.3125rem;
  }

  .sales-problems__wrapper {
    gap: 6rem;
  }

  .sales-problems__header {
    gap: 6rem;
  }

  /* .sales-problems__header span {
    letter-spacing: 0.2rem;
    font-size: 1.25rem;
    line-height: 1.5rem;
  } */

  .sales-problems-header__intro {
    flex-direction: row;
    align-items: center;
  }

  /* .sales-problems-header__intro p {
    font-weight: 700;
    font-size: 1.25rem;
    line-height: 1.5rem;
  } */

  .sales-problems__examples {
    flex-direction: row;
    /* gap: 2rem; */
  }

  .sales-problems__example {
    flex: 1;
    gap: 1.875rem;
  }

  /* .sales-problems__examples p {
    font-size: 1rem;
    line-height: 19px;
  } */

  .sales-problems__conclusion {
    gap: 2.125rem;
  }

  /* .sales-problems__conclusion span {
    font-size: 1.25rem;
    line-height: 1.5rem;
  } */

  #sales-philosophy {
    padding: 10vh 10%;
  }

  .sales .split {
    flex-direction: row;
    /* padding-inline: 15rem; */
  }

  /* .sales .split p {
    text-align: left;
    font-size: 1.25rem;
    line-height: 1.5rem;
  } */

  /* .sales .title h3 {
    font-size: 2.5rem;
  } */

  .sales-mini-cards-wrapper {
    flex-direction: row;
  }
  .sales-mini-card {
    flex: 1;
  }
  /* .sales-mini-card p {
    text-align: center !important;
    font-size: 18px;
    color: var(--clr-accent);
  } */

  .solution {
    padding-block: 6.4375rem 3.375rem;
    height: 860px;
  }

  .solution__wrapper {
    gap: 3.375rem;
    padding-inline: 10rem;
  }
  /* 
  .solution__header {
    padding-inline: 10rem;
  } */

  /* .solution__header h3 {
    font-size: 2.5rem;
    line-height: 3rem;
  } */

  .solution__examples {
    gap: 4rem;
    text-align: left;
  }

  .solution__examples > *:nth-child(2) {
    flex-direction: row-reverse;
  }

  .solution__example {
    flex-direction: row;
    width: 100%;
  }

  /* .solution__example p {
    font-size: 1.25rem;
    line-height: 1.5rem;
  } */

  .solution__example > * {
    flex: 1;
  }

  .whats-next {
    padding-block: 10rem;
    position: relative;
    z-index: -2;
  }

  .whats-next__wrapper {
    gap: 6.25rem;
  }

  .whats-next .shadow {
    left: 2rem;
    top: 2rem;
    font-size: 5.9375rem;
    line-height: 7.1875rem;
  }

  .roadmap {
    flex-direction: row;
    left: 5rem;
    min-height: 50vh;
  }

  .roadMapPart {
    height: 320px;
  }

  .roadmapPart--primary {
    /* padding-top: 6.5625rem; */
    position: relative;
    top: 3.3rem;
    /* left: -4rem; */
    padding-left: 0rem;
  }

  .sales-end__questions {
    flex-direction: row;
    gap: 11rem;
  }

  .sales-end__question {
    flex: 1;
    padding-inline: 7rem;
    max-width: none;
  }
  /* 
  .sales-end__question p {
    font-size: 1.25rem;
    line-height: 1.875rem;
  } */

  .sales-ending {
    padding-inline: 10rem;
  }
}
