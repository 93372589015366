.social-links {
  display: flex;
  align-items: center;
  gap: 1.375rem;
  display: none;
}

.social-links img {
  width: 36px;
  height: auto;
}

.social-links li {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  /* z-index: -11; */
}

/* .social-links img {
  position: relative;
  z-index: -8;
} */

.social-links li::before {
  content: "";
  position: absolute;
  width: 3em;
  height: 3em;
  background-color: transparent;
  transition: var(--transition-speed) ease-out;
  z-index: -1;
}

.social-links li img#linkedin {
  filter: invert(100%);
}

.social-links li:hover::before,
.social-links li:active::before {
  background-color: var(--clr-neutral);
}

.social-links li:hover img,
.social-links li:active img {
  filter: invert(100%);
}

.social-links li:hover img#linkedin,
.social-links li:active img#linkedin {
  filter: unset;
}

@media (min-width: 93.75em) {
  .social-links {
    gap: 2.5rem;
    display: flex;
    grid-area: social-links;
    margin: 0;
    justify-content: center;
  }
  .social-links li::before {
    width: 4.5em;
    height: 4.5em;
  }
}
