.td-world {
  background-color: black;
  position: relative;
  z-index: 0;
  overflow: hidden;
}

.td-world .intro {
  user-select: none;
  /* padding-block: 2rem 4rem; */
  position: relative;
  padding-top: 15vh;
  min-height: 100vh;
  width: 100%;
  padding-bottom: 10vh;
}

.under-construction {
  position: relative;
  min-height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

#background-video {
  z-index: -1;
  height: 100%;
  width: 100%;
  top: 0;
  padding: none;
  position: absolute;
}

.intro__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2.5rem;
}

.intro-body {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: column;
  gap: 5vh;
}

.intro-body-link {
  color: var(--clr-primary);
}

.intro__header {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  text-align: center;
}

.work {
  padding-top: 5vh;
  padding-bottom: 5vh;
}

.work-wrapper {
  min-height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.examples-section {
  padding: 7vh 0;
  width: 100%;
  display: flex;
  justify-content: center;
}
.examples-carousel {
  width: 95vw;
  max-width: 1200px;
}
/* .examples-carousel .react-multiple-carousel__arrow--left {
  left: calc(-4% - 1px) !important;
}
.examples-carousel .react-multiple-carousel__arrow--right {
  right: calc(-4% - 1px) !important;
} */
.website-example {
  width: 100%;
  max-height: 500px;
  display: flex;
  justify-content: center;
}
.website-example a {
  width: 200px;
  height: 300px;
  display: flex;
  justify-content: center;
}
.website-example video {
  width: auto;
  height: 100%;
}
.website-example img {
  width: auto;
  height: 100%;
}

.video-3d {
  width: 100%;
  height: auto;
  position: relative;
}
.video-3d .video-thumbnail {
  position: absolute;
  top: 0;
  left: 50%;
  right: 50%;
  transform: translateX(-50%);
  height: 95%;
  z-index: -1;
  width: auto;
}

.model-3d {
  height: 500px;
  width: 100%;
  display: flex;
  align-items: center;
}

@media (min-width: 68em) {
  .intro .shadow {
    left: 1.5rem;
    text-align: center;
    top: 2rem;
  }
  .website-example a {
    width: 300px;
    height: 500px;
  }

  .intro__wrapper {
    gap: 6.5rem;
  }

  .intro__header {
    gap: 5rem;
  }

  .intro-body {
    flex-direction: row;
    gap: 5%;
  }
  .examples-carousel {
    width: 90vw;
  }
  .model-3d {
    height: 800px;
  }
}
