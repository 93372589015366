/*  FOOTER  */
footer {
  background-color: var(--clr-neutral-dark);
  padding: 15vh 10% 10vh 10%;
  position: relative;
  z-index: 2;
}

/* footer > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 1.5rem;
} */

footer {
  display: grid;
  text-align: center;
  gap: 1.5625rem;
  justify-items: center;
  grid-template-areas:
    "button"
    "logo"
    "info-social"
    "copyright";
}

footer button {
  width: 150px;
  height: 150px;
  font-weight: 800;
  font-size: 20px;
  line-height: 32px;
  color: var(--clr-neutral);
  background: linear-gradient(161.41deg, #7035ab 8.84%, #1e1824 92.14%);
  position: absolute;
  /* justify-self: center; */
  bottom: 50px;
  /* z-index: 100; */
  /* padding: 3.875rem 1.625rem; */
  /* padding: 6vh 5vh; */
  border-radius: 50%;
}

footer button {
  animation: pulse-with-glow 1.5s linear infinite;
}

@keyframes pulse-with-glow {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 16px var(--clr-accent), inset 0 0 16px var(--clr-accent);
  }
  70% {
    transform: scale(1);
    box-shadow: 0 0 32px var(--clr-accent), inset 0 0 28px var(--clr-accent);
  }
  100% {
    transform: scale(0.95);
    box-shadow: 0 0 16px var(--clr-accent), inset 0 0 16px var(--clr-accent);
  }
}

footer > button {
  grid-area: button;
}

footer > .wrapper {
  grid-area: logo;
  padding-inline: 20%;
}

footer > .info-social {
  grid-area: info-social;
}

footer > .lower {
  grid-area: copyright;
}

footer .info-social {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

footer .nav__info {
  align-items: center;
}

.lower > p {
  color: #868686;
  font-size: 0.75rem;
}

footer .social-links {
  display: flex;
  padding-inline: 5%;
}

@media (min-width: 68rem) {
  footer {
    padding: 6.25rem 7.5rem 4rem;
    align-items: center;
    justify-items: flex-start;
  }

  footer {
    grid-template-columns: 1fr 3fr 1fr;
    grid-template-areas:
      "logo button info-social"
      "copyright button info-social";
  }

  footer .nav__info {
    align-items: flex-end;
  }

  footer button {
    width: 250px;
    height: 250px;
    font-weight: 800;
    font-size: 30px;
    line-height: 49px;
    color: var(--clr-neutral);
    background: linear-gradient(161.41deg, #7035ab 8.84%, #1e1824 92.14%);
    position: absolute;
    bottom: 10rem;
    justify-self: center;
    /* z-index: 100; */
    /* padding: 7.375rem 5.625rem; */

    border-radius: 50%;
  }

  footer > .wrapper {
    padding-inline: unset;
  }

  .lower {
    margin-top: 2rem;
  }
  .lower p {
    margin-top: 2rem;
    text-align: left;
    font-size: 1rem;
  }

  footer .social-links {
    justify-content: end;
    padding-inline: unset;
  }

  footer .nav__info {
    text-align: right;
    margin-bottom: 20px;
  }
}
