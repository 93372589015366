button a {
  font-size: unset;
  line-height: unset;
  text-decoration: none;
  font-weight: unset;
  color: unset;
}

.primary-btn {
  color: var(--clr-neutral-dark);
  background-color: var(--clr-primary);
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  padding: 10px 3rem;
  border-radius: 3px;
}

.primary-btn:hover {
  box-shadow: 0 5px 15px var(--clr-primary);
}

.accent-btn {
  color: var(--clr-neutral);
  background-color: var(--clr-accent);
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  padding: 10px 3rem;
  border-radius: 3px;
}

.accent-btn:hover {
  box-shadow: 0 5px 15px var(--clr-accent);
}

@media (min-width: 68em) {
  .primary-btn,
  .accent-btn {
    font-size: 24px;
    line-height: 29px;
    padding: 14px 4rem;
    border-radius: 5px;
  }
}
