/* ABOUT US PAGE */

.about {
  background-color: var(--clr-accent-dark);
  position: relative;
  overflow-x: hidden;
}

.about .container {
  --max-width: 1570px;
}

.about-us {
  position: relative;
  z-index: 0;
  padding-block: 15vh;
}

.about-us__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2.1875rem;
  text-align: center;
}

.about-us__header {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 7vh;
}

.about-us .shadow {
  left: 0.5rem;
  top: 0.7rem;
  font-size: 34px;
  line-height: 41px;
}

.about-us__card {
  max-width: 700px;
  padding: 50px 5% 0px;
  border: 2px solid var(--clr-primary);
  border-image-source: linear-gradient(
    180deg,
    #fbd938 0%,
    rgba(251, 217, 56, 0.1) 100%
  );
  border-image-slice: 1;
  min-height: 500px;
  background-color: rgb(16, 5, 23, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 2rem;
}

.play-video-button {
  width: 70px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 5px solid var(--clr-primary);
  cursor: pointer;
  box-shadow: 0 1px 6px 1px var(--clr-primary);
}
.play-video-button i {
  font-size: 30px;
  color: var(--clr-primary);
  position: relative;
  left: 3px;
}

.about-us__body {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3rem;
  margin-top: 5vh;
}

.ceo-section {
  padding-block: 4.5rem 10rem;
}

.ceo__wrapper {
  display: flex;
  flex-direction: column;
  text-align: justify;
  align-items: center;
  gap: 4rem;
}

.ceo__header h3 {
  font-size: 1.25rem;
  line-height: 1.5rem;
  text-align: center;
  margin-bottom: 30px;
}

.ceo__image {
  display: flex;
  justify-content: center;
}
.ceo__image img {
  max-height: 400px;
}

.ceo__body {
  display: flex;
  flex-direction: column-reverse;
  gap: 3rem;
  text-align: justify;
}

.ceo__speech p::before {
  content: open-quote;
  position: absolute;
  /* top: 1rem; */
  font-size: 10rem;
  color: var(--clr-accent);
  font-family: Inter;
}

.ceo__end p {
  font-weight: 700;
  line-height: 1.25rem;
  letter-spacing: 0.2em;
  text-align: center;
  text-transform: uppercase;
}

@media (min-width: 68rem) {
  .about-us__wrapper {
    gap: 4.625rem;
  }

  .about-us__header {
    /* gap: 7vh; */
    padding-inline: 3rem;
  }

  .about-us__body {
    flex-direction: row;
    gap: 5%;
    /* align-items: flex-start; */
  }

  /* .about-us__body p {
    font-family: Inter;
    font-size: 20px;
    line-height: 24px;
  } */

  .about-us .shadow {
    left: 1.5rem;
    top: 2rem;
    font-size: 6.875rem;
    font-weight: 900;
    line-height: 8.3rem;
  }

  .about-us__card {
    flex: 1;
    flex-direction: column;
  }

  .ceo-section {
    padding-block: 6rem 15rem;
  }

  .ceo__wrapper {
    text-align: left;
    gap: 10rem;
  }

  .ceo__image img {
    max-height: 400px;
    min-width: 200px;
  }

  .ceo__header h3 {
    font-size: 2.5rem;
    line-height: 3rem;
  }

  .ceo__body {
    flex-direction: row;
    align-items: center;
    gap: 7%;
  }

  /* .ceo__body p {
    font-size: 1rem;
    line-height: 19px;
  } */

  .ceo__end {
    /* padding-inline: 5rem; */
    text-align: center;
  }

  /* .ceo__end p {
    font-size: 1.25rem;
    line-height: 1.875rem;
  } */
}
